/*
	Spectral by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Spotlight */

	.spotlight {
		display: block;
	}

		.spotlight .image {
			display: inline-block;
			vertical-align: top;
		}

		.spotlight .content {
			padding: 4em 4em 2em 4em ;
			display: inline-block;
		}

		.spotlight:after {
			clear: both;
			content: '';
			display: block;
		}

/* Features */

	.features {
		display: block;
	}

		.features li {
			float: left;
		}

		.features:after {
			content: '';
			display: block;
			clear: both;
		}

/* Banner + Wrapper (style4) */

	#banner,
	.wrapper.style4 {
		background-image: url("../../images/banner.jpg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}

		#banner:before,
		.wrapper.style4:before {
			background: #000000;
			content: '';
			height: 100%;
			left: 0;
			opacity: 0.5;
			position: absolute;
			top: 0;
			width: 100%;
		}

		#banner .inner,
		.wrapper.style4 .inner {
			position: relative;
			z-index: 1;
		}

/* Banner */

	#banner {
		padding: 14em 0 12em 0 ;
		height: auto;
	}

		#banner:after {
			display: none;
		}

/* CTA */

	#cta .inner header {
		float: left;
	}

	#cta .inner .actions {
		float: left;
	}

	#cta .inner:after {
		clear: both;
		content: '';
		display: block;
	}

/* Main */

	#main > header {
		background-image: url("../../images/banner.jpg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}

		#main > header:before {
			background: #000000;
			content: '';
			height: 100%;
			left: 0;
			opacity: 0.5;
			position: absolute;
			top: 0;
			width: 100%;
		}

		#main > header > * {
			position: relative;
			z-index: 1;
		}